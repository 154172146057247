<template>
  <div class="merchant-benefits">
    <nav-header :backUrl="'/my'"></nav-header>
    <div class="merchant-benefits-content">
      <img
        class="content-img"
        v-for="(item, idx) in images"
        :src="item"
        :key="idx"
      />
      <div class="merchant-benefits-split">
        <div class="merchant-benefits-split-line"></div>
        <div class="merchant-benefits-split-text">更多权益</div>
      </div>
      <div class="merchant-benefits-list">
        <div
          class="merchant-benefits-list-item"
          v-for="(item, idx) in list"
          :key="idx"
          @click="goBenefitDetail(item)"
        >
          <div
            class="item-image"
            :style="{
              backgroundImage: `url(${item.bigImageUrl})`,
            }"
          ></div>
          <div class="item-content">
            <div class="item-content-1">
              <img :src="item.smallImageUrl" />
            </div>
            <div class="item-content-2">
              {{ item.goodsSkuName || "--" }}
            </div>
            <div class="item-content-3">
              {{ item.benefitDescription || "--" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NavHeader from "@/components/NavHeader";
import { configsMap } from "./const";
import { getAllBenefits } from "@/service";
export default {
  data() {
    return {
      merchantId: null,
      images: [],
      list: [],
    };
  },
  components: {
    NavHeader,
  },

  created() {
    this.merchantId = this.$route.params.merchantId;
    if (!this.merchantId) {
      this.$message.error("未指定商户Id");
      return;
    }
    this.images = (configsMap[this.merchantId] || {}).images || [];
    this.getAllBenefits();
  },
  methods: {
    async getAllBenefits() {
      const { data: list } = await getAllBenefits(this.merchantId);
      this.list = list || [];
    },
    goBenefitDetail(item) {
      let images = [];
      try {
        const obj = JSON.parse(item.benefitDetailImg);
        images = (obj || {}).imgs || [];
      } catch (error) {
        //todo
      }
      this.$router.push({
        name: "benefitDetail",
        params: { title: item.goodsSkuName, images },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.merchant-benefits {
  padding-top: 50px;
  .merchant-benefits-content {
    .content-img {
      width: 100%;
    }
    .merchant-benefits-list {
      margin: 0.38rem auto 0.2rem;
      padding: 0 0.1rem;
      width: 3.75rem;
      overflow: auto;
      .merchant-benefits-list-item {
        width: 1.72rem;
        height: 2.94rem;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
        border-radius: 2px;
        overflow: hidden;
        margin-bottom: 0.1rem;
        &:nth-child(2n) {
          float: right;
        }
        &:nth-child(2n + 1) {
          float: left;
        }
        .item-image {
          height: 1.4rem;
          @include common-bg;
        }
        .item-content {
          padding: 0.15rem;
          .item-content-1 {
            img {
              width: 0.24rem;
              height: 0.24rem;
            }
            margin-bottom: 0.1rem;
          }
          .item-content-2 {
            color: #111;
            font-size: 0.16rem;
            margin-bottom: 0.05rem;
            @include text-overflow;
          }
          .item-content-3 {
            color: #808080;
            font-size: 0.14rem;
            height: 0.6rem;

            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }
        }
      }
    }
    .merchant-benefits-split {
      line-height: 0.38rem;
      height: 0.38rem;
      padding: 0 0.15rem;
      position: relative;
      .merchant-benefits-split-line {
        position: absolute;
        top: 0.15rem;
        border: 2px dashed #e3e5e6;
        width: calc(100% - 0.3rem);
        left: 0.15rem;
        height: 2px;
      }
      .merchant-benefits-split-text {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        background-color: #9fc83c;
        width: 2.25rem;
        height: 0.38rem;
        color: #fff;
        font-size: 0.22rem;
        font-weight: bold;
        line-height: 0.38rem;
        text-align: center;
        // &::before {
        //   content: "";
        //   position: absolute;
        //   width: 0.3rem;
        //   height: 0.6rem;
        //   border-radius: 0 0.3rem 0.3rem 0;
        //   line-height: 0.6rem;
        // }
        // &::after {
        //   content: "";
        //   position: absolute;
        //   width: 0.3rem;
        //   height: 0.6rem;
        //   border-radius: 0.3rem 0 0 0.3rem;
        //   line-height: 0.6rem;
        // }
      }
    }
  }
}
</style>