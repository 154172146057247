export const configs = [
  {
    merchantId: 200,
    images: [
      require("@/assets/images/benefits/health/1.jpg"),
      require("@/assets/images/benefits/health/2.jpg"),
      require("@/assets/images/benefits/health/3.jpg"),
    ],
  },
];

export const configsMap = configs.reduce((s, v) => {
  s[v.merchantId] = v;
  return s;
}, {});
